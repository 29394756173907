import ReactDOM from "react-dom";
import App from "./App";

//router
import { BrowserRouter } from "react-router-dom";

import { QueryClient, QueryClientProvider } from "react-query";

import { Provider } from "react-redux";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

//reducer
import { store } from "./store";

import "./i18n";

const queryClient = new QueryClient();

ReactDOM.render(
  <BrowserRouter basename="/">
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <App />
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </Provider>
    </QueryClientProvider>
  </BrowserRouter>,
  document.getElementById("root")
);
