import { useState, useContext, memo, Fragment } from "react";

import { Link, useLocation } from "react-router-dom";

import {
  Accordion,
  useAccordionButton,
  AccordionContext,
  Nav,
  Tooltip,
  OverlayTrigger,
} from "react-bootstrap";

import { BsFillBox2Fill, BsFileBarGraph } from "react-icons/bs";
import { AiOutlineAppstoreAdd } from "react-icons/ai";
import { IoSchoolSharp, IoSchoolOutline } from "react-icons/io5";
import { MdAssignmentAdd, MdPayment } from "react-icons/md";
import { GiTeacher, GiSchoolBag } from "react-icons/gi";
import { VscGitPullRequestCreate } from "react-icons/vsc";
import { FiUsers, FiUser } from "react-icons/fi";
import { BiSupport } from "react-icons/bi";
import { SiCivicrm } from "react-icons/si";
import { IoMdSearch } from "react-icons/io";
import { FaMoneyCheck } from "react-icons/fa";
import { useSelector } from "react-redux";
import * as SettingSelector from "../../../../store/setting/selectors";
import RightArrow from "../../../custom/RightArrow";

function CustomToggle({ children, eventKey, onClick }) {
  const { activeEventKey } = useContext(AccordionContext);

  const decoratedOnClick = useAccordionButton(eventKey, (active) =>
    onClick({ state: !active, eventKey: eventKey })
  );

  const isCurrentEventKey = activeEventKey === eventKey;

  return (
    <Link
      to="#"
      aria-expanded={isCurrentEventKey ? "true" : "false"}
      className="nav-link"
      role="button"
      onClick={(e) => {
        decoratedOnClick(isCurrentEventKey);
      }}
    >
      {children}
    </Link>
  );
}

const VerticalNav = memo(() => {
  let userType = localStorage.getItem("userType");

  const [activeMenu, setActiveMenu] = useState(false);
  const [active, setActive] = useState("");

  const data = [
    {
      department: "admin",
      permission: [
        "Dashboard",
        "Student",
        "Teachers",
        "Teacher Request",
        "Enrollment",
        "Staff",
        "Users List",
        "Crm",
        "Courses",
        "Transaction",
        "Support",
        "Payroll",
        "Profile",
        "Settings",
      ],
    },
    {
      department: "regional_admin",
      permission: [
        "Dashboard",
        "Student",
        "Teachers",
        "Teacher Request",
        "Enrollment",
        "Staff",
        "Users List",
        "Crm",
        "Courses",
        "Transaction",
        "Support",
        "Payroll",
        "Profile",
        "Settings",
      ],
    },
    {
      department: "spm",
      permission: ["Staff Dashboard","Courses","Enrollment","Teachers","Profile", "Student","Crm", "Support"],
    },
    {
      department: "hr_assistant",
      permission: ["Staff Dashboard","Teachers","Teacher Request","Enrollment","Profile","Support"],
    },
    {
      department: "hr_manager",
      permission: ["Staff Dashboard","Teachers","Teacher Request","Enrollment","Profile","Support"],
    },
    {
      department: "finance_manager",
      permission: ["Student","Teachers","Staff","Transaction","Payroll", "Profile"],
    },
    {
      department: "accountant",
      permission: ["Student","Teachers","Staff","Transaction","Payroll", "Profile"],
    },
    {
      department: "cse1",
      permission: ["Support", "Profile"],
    },
    {
      department: "cse2",
      permission: ["Support", "Profile"],
    },
    {
      department: "general_cse",
      permission: ["Support", "Profile"],
    },
    {
      department: "admin_assistant",
      permission: [
        "Student",
        "Teachers",
        "Teacher Request",
        "Training ",
        "Withdrawal ",
        "Demo class ",
        "Upgrade ",
        "Enrollment",
        "Course",
        "Demo Class",
        "Tuition",
        "Staff",
        "Crm",
        "Courses",
        "Support",
        "Payroll",
        "Profile",
      ],
    },
    {
      department: "marketing_executive",
      permission: ["Crm", "Profile"],
    },
    {
      department: "it_specialist",
      permission: ["Profile"],
    },
    {
      department: "digital_marketing_staff",
      permission: ["Crm", "Profile"],
    },
    {
      department: "telecaller",
      permission: ["Support", "Profile"],
    },
    {
      department: "other",
      permission: ["Profile"],
    },
  ];

  const permission = data.filter((item) => item.department == userType);
  let location = useLocation();
  const mode = useSelector(SettingSelector.theme_scheme_direction);

  return (
    <Fragment>
      <Accordion as="ul" className="navbar-nav iq-main-menu">
        <li className="nav-item static-item"></li>
        {permission[0]?.permission?.includes("Dashboard") && (<li
          style={
            location.pathname === "/dashboard"
              ? { background: "#AB0D47", borderRadius: "6px" }
              : {}
          }
          className={` nav-item `}
        >
          <Link className={`nav-link`} aria-current="page" to="/dashboard">
            <OverlayTrigger
              placement={mode === "ltr" ? "right" : "left"}
              overlay={<Tooltip>Dashboard</Tooltip>}
            >
              <i className="icon">
                <svg
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    opacity="0.4"
                    d="M9.71701 0.333252H11.9743C12.9092 0.333252 13.6667 1.09715 13.6667 2.03989V4.31627C13.6667 5.25901 12.9092 6.02291 11.9743 6.02291H9.71701C8.78216 6.02291 8.02466 5.25901 8.02466 4.31627V2.03989C8.02466 1.09715 8.78216 0.333252 9.71701 0.333252Z"
                    fill={location.pathname === "/dashboard" ? "#fff" : "black"}
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M2.02569 0.333252H4.283C5.21785 0.333252 5.97535 1.09715 5.97535 2.03989V4.31627C5.97535 5.25901 5.21785 6.02291 4.283 6.02291H2.02569C1.09085 6.02291 0.333344 5.25901 0.333344 4.31627V2.03989C0.333344 1.09715 1.09085 0.333252 2.02569 0.333252ZM2.02569 7.97693H4.283C5.21785 7.97693 5.97535 8.74083 5.97535 9.68357V11.9599C5.97535 12.902 5.21785 13.6666 4.283 13.6666H2.02569C1.09085 13.6666 0.333344 12.902 0.333344 11.9599V9.68357C0.333344 8.74083 1.09085 7.97693 2.02569 7.97693ZM11.9743 7.97693H9.71702C8.78217 7.97693 8.02467 8.74083 8.02467 9.68357V11.9599C8.02467 12.902 8.78217 13.6666 9.71702 13.6666H11.9743C12.9092 13.6666 13.6667 12.902 13.6667 11.9599V9.68357C13.6667 8.74083 12.9092 7.97693 11.9743 7.97693Z"
                    fill={location.pathname === "/dashboard" ? "#fff" : "black"}
                  />
                </svg>
              </i>
            </OverlayTrigger>
            <span
              className={`item-name ${
                location.pathname === "/dashboard" ? "text-white" : "text-black"
              }`}
              style={{ fontWeight: "600" }}
            >
              Dashboard
            </span>
          </Link>
        </li>)}
        {permission[0]?.permission?.includes("Staff Dashboard") &&( <li
          style={
            location.pathname === "/staff-dashboard"
              ? { background: "#AB0D47", borderRadius: "6px" }
              : {}
          }
          className={` nav-item `}
        >
          <Link
            className={`nav-link`}
            aria-current="page"
            to="/staff-dashboard"
          >
            <OverlayTrigger
              placement={mode === "ltr" ? "right" : "left"}
              overlay={<Tooltip>Staff Dashboard</Tooltip>}
            >
              <i className="icon">
                <svg
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    opacity="0.4"
                    d="M9.71701 0.333252H11.9743C12.9092 0.333252 13.6667 1.09715 13.6667 2.03989V4.31627C13.6667 5.25901 12.9092 6.02291 11.9743 6.02291H9.71701C8.78216 6.02291 8.02466 5.25901 8.02466 4.31627V2.03989C8.02466 1.09715 8.78216 0.333252 9.71701 0.333252Z"
                    fill={
                      location.pathname === "/staff-dashboard"
                        ? "#fff"
                        : "black"
                    }
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M2.02569 0.333252H4.283C5.21785 0.333252 5.97535 1.09715 5.97535 2.03989V4.31627C5.97535 5.25901 5.21785 6.02291 4.283 6.02291H2.02569C1.09085 6.02291 0.333344 5.25901 0.333344 4.31627V2.03989C0.333344 1.09715 1.09085 0.333252 2.02569 0.333252ZM2.02569 7.97693H4.283C5.21785 7.97693 5.97535 8.74083 5.97535 9.68357V11.9599C5.97535 12.902 5.21785 13.6666 4.283 13.6666H2.02569C1.09085 13.6666 0.333344 12.902 0.333344 11.9599V9.68357C0.333344 8.74083 1.09085 7.97693 2.02569 7.97693ZM11.9743 7.97693H9.71702C8.78217 7.97693 8.02467 8.74083 8.02467 9.68357V11.9599C8.02467 12.902 8.78217 13.6666 9.71702 13.6666H11.9743C12.9092 13.6666 13.6667 12.902 13.6667 11.9599V9.68357C13.6667 8.74083 12.9092 7.97693 11.9743 7.97693Z"
                    fill={
                      location.pathname === "/staff-dashboard"
                        ? "#fff"
                        : "black"
                    }
                  />
                </svg>
              </i>
            </OverlayTrigger>
            <span
              className={`item-name ${
                location.pathname === "/staff-dashboard"
                  ? "text-white"
                  : "text-black"
              }`}
              style={{ fontWeight: "600" }}
            >
              Staff Dashboard
            </span>
          </Link>
        </li>)}
        {permission[0]?.permission?.includes("Student") && (
          <Accordion.Item
            as="li"
            eventKey="students"
            bsPrefix={`nav-item `}
            onClick={() => setActive("students")}
          >
            <CustomToggle
              eventKey="students"
              onClick={(activeKey) => setActiveMenu(activeKey)}
            >
              <OverlayTrigger
                placement={mode !== "en" ? "right" : "left"}
                overlay={<Tooltip>Students</Tooltip>}
              >
                <i className="icon">
                  <IoSchoolSharp className="text-black" />
                </i>
              </OverlayTrigger>
              <span
                className="item-name text-black"
                style={{ fontWeight: "600" }}
              >
                Students
              </span>
              <i className="right-icon">
                <RightArrow />
              </i>
            </CustomToggle>
            <Accordion.Collapse eventKey="students">
              <ul className="sub-nav">
                <Nav.Item
                  as="li"
                  style={
                    location.pathname === `/students/student-list` ||
                    location.pathname.includes(
                      "/students/unassigned-students"
                    ) ||
                    location.pathname === `/students/assessment-counselling` ||
                    location.pathname === `/students/student-status`
                      ? { background: "#FFF0F5", borderRadius: "6px" }
                      : {}
                  }
                >
                  <Link className={` nav-link`} to="/students/student-list">
                    <i className="icon">
                      <AiOutlineAppstoreAdd />
                    </i>
                    <OverlayTrigger
                      placement={mode !== "en" ? "right" : "left"}
                      overlay={<Tooltip>Students List</Tooltip>}
                    >
                      <i className="sidenav-mini-icon">
                        <AiOutlineAppstoreAdd />
                      </i>
                    </OverlayTrigger>
                    <span
                      className="item-name text-black"
                      style={{ fontWeight: "600" }}
                    >
                      Students List
                    </span>
                  </Link>
                </Nav.Item>

                <Nav.Item
                  as="li"
                  style={
                    location.pathname === `/student-reports`
                      ? { background: "#FFF0F5", borderRadius: "6px" }
                      : {}
                  }
                >
                  <Link className={` nav-link`} to="/student-reports">
                    <i className="icon">
                      <AiOutlineAppstoreAdd />
                    </i>
                    <OverlayTrigger
                      placement={mode !== "en" ? "right" : "left"}
                      overlay={<Tooltip>Reports</Tooltip>}
                    >
                      <i className="sidenav-mini-icon">
                        <AiOutlineAppstoreAdd />
                      </i>
                    </OverlayTrigger>
                    <span
                      className="item-name text-black"
                      style={{ fontWeight: "600" }}
                    >
                      Reports
                    </span>
                  </Link>
                </Nav.Item>
              </ul>
            </Accordion.Collapse>
          </Accordion.Item>
        )}
        {permission[0]?.permission?.includes("Teachers") && (
          <Accordion.Item
            as="li"
            eventKey="teachers"
            bsPrefix={`nav-item `}
            onClick={() => setActive("teachers")}
          >
            <CustomToggle
              eventKey="teachers"
              onClick={(activeKey) => setActiveMenu(activeKey)}
            >
              <OverlayTrigger
                placement={mode !== "en" ? "right" : "left"}
                overlay={<Tooltip>Teachers</Tooltip>}
              >
                <i className="icon">
                  <GiTeacher className="text-black" />
                </i>
              </OverlayTrigger>
              <span
                className="item-name text-black"
                style={{ fontWeight: "600" }}
              >
                Teachers
              </span>
              <i className="right-icon">
                <RightArrow />
              </i>
            </CustomToggle>
            <Accordion.Collapse eventKey="teachers">
              <ul className="sub-nav">
                <Nav.Item
                  as="li"
                  style={
                    location.pathname === `/teachers/teachers-list` ||
                    location.pathname.includes(
                      "/teachers/unassigned-teacher"
                    ) ||
                    location.pathname === `/teachers/trainers-list`
                      ? { background: "#FFF0F5", borderRadius: "6px" }
                      : {}
                  }
                >
                  <Link className={` nav-link`} to="/teachers/teachers-list">
                    <i className="icon">
                      <AiOutlineAppstoreAdd />
                    </i>
                    <OverlayTrigger
                      placement={mode !== "en" ? "right" : "left"}
                      overlay={<Tooltip>Teachers List</Tooltip>}
                    >
                      <i className="sidenav-mini-icon">
                        <AiOutlineAppstoreAdd />
                      </i>
                    </OverlayTrigger>
                    <span
                      className="item-name text-black"
                      style={{ fontWeight: "600" }}
                    >
                      Teachers List
                    </span>
                  </Link>
                </Nav.Item>

                {userType!=='spm' &&<Nav.Item
                  as="li"
                  style={
                    location.pathname === `/teacher-reports`
                      ? { background: "#FFF0F5", borderRadius: "6px" }
                      : {}
                  }
                >
                  <Link className={` nav-link`} to="/teacher-reports">
                    <i className="icon">
                      <AiOutlineAppstoreAdd />
                    </i>
                    <OverlayTrigger
                      placement={mode !== "en" ? "right" : "left"}
                      overlay={<Tooltip>Reports</Tooltip>}
                    >
                      <i className="sidenav-mini-icon">
                        <AiOutlineAppstoreAdd />
                      </i>
                    </OverlayTrigger>
                    <span
                      className="item-name text-black"
                      style={{ fontWeight: "600" }}
                    >
                      Reports
                    </span>
                  </Link>
                </Nav.Item>}
              </ul>
            </Accordion.Collapse>
          </Accordion.Item>
        )}
        {permission[0]?.permission?.includes("Teacher Request") && (
          <Accordion.Item
            as="li"
            eventKey="teachers-request"
            bsPrefix={`nav-item `}
            onClick={() => setActive("teachers-request")}
          >
            <CustomToggle
              eventKey="teachers-request"
              onClick={(activeKey) => setActiveMenu(activeKey)}
            >
              <OverlayTrigger
                placement={mode !== "en" ? "right" : "left"}
                overlay={<Tooltip>Teacher Requests</Tooltip>}
              >
                <i className="icon">
                  <VscGitPullRequestCreate className="text-black" />
                </i>
              </OverlayTrigger>
              <span
                className="item-name text-black"
                style={{ fontWeight: "600" }}
              >
                Teacher Requests
              </span>
              <i className="right-icon">
                <RightArrow />
              </i>
            </CustomToggle>
            <Accordion.Collapse eventKey="teachers-request">
              <ul className="sub-nav">
               {(userType == "admin"||userType==="regional_admin")&& <Nav.Item
                  as="li"
                  style={
                    location.pathname === `/requests/requested` ||
                    location.pathname.includes("/requests/requested") ||
                    location.pathname === `/requests/rejected`
                      ? { background: "#FFF0F5", borderRadius: "6px" }
                      : {}
                  }
                >
                  <Link className={` nav-link`} to="/requests/requested">
                    <i className="icon">
                      <AiOutlineAppstoreAdd />
                    </i>
                    <OverlayTrigger
                      placement={mode !== "en" ? "right" : "left"}
                      overlay={<Tooltip>Teacher</Tooltip>}
                    >
                      <i className="sidenav-mini-icon">
                        <AiOutlineAppstoreAdd />
                      </i>
                    </OverlayTrigger>
                    <span
                      className="item-name text-black"
                      style={{ fontWeight: "600" }}
                    >
                      Teacher
                    </span>
                  </Link>
                </Nav.Item>}

                <Nav.Item
                  as="li"
                  style={
                    location.pathname === `/teacher-trainings-list` ||
                    location.pathname.includes("/teacher-trainings-list")
                      ? { background: "#FFF0F5", borderRadius: "6px" }
                      : {}
                  }
                >
                  <Link className={` nav-link`} to="/teacher-trainings-list">
                    <i className="icon">
                      <AiOutlineAppstoreAdd />
                    </i>
                    <OverlayTrigger
                      placement={mode !== "en" ? "right" : "left"}
                      overlay={<Tooltip>Training</Tooltip>}
                    >
                      <i className="sidenav-mini-icon">
                        <AiOutlineAppstoreAdd />
                      </i>
                    </OverlayTrigger>
                    <span
                      className="item-name text-black"
                      style={{ fontWeight: "600" }}
                    >
                      Training
                    </span>
                  </Link>
                </Nav.Item>
                <Nav.Item
                  as="li"
                  style={
                    location.pathname === `/teacher-withdrawals` ||
                    location.pathname.includes("/teacher-withdrawals")
                      ? { background: "#FFF0F5", borderRadius: "6px" }
                      : {}
                  }
                >
                  <Link className={` nav-link`} to="/teacher-withdrawals">
                    <i className="icon">
                      <AiOutlineAppstoreAdd />
                    </i>
                    <OverlayTrigger
                      placement={mode !== "en" ? "right" : "left"}
                      overlay={<Tooltip>Withdraws</Tooltip>}
                    >
                      <i className="sidenav-mini-icon">
                        <AiOutlineAppstoreAdd />
                      </i>
                    </OverlayTrigger>
                    <span
                      className="item-name text-black"
                      style={{ fontWeight: "600" }}
                    >
                      Withdraws
                    </span>
                  </Link>
                </Nav.Item>
                <Nav.Item
                  as="li"
                  style={
                    location.pathname === `/teacher-demoCourse` ||
                    location.pathname.includes("/teacher-demoCourse")
                      ? { background: "#FFF0F5", borderRadius: "6px" }
                      : {}
                  }
                >
                  <Link className={` nav-link`} to="/teacher-demoCourse">
                    <i className="icon">
                      <AiOutlineAppstoreAdd />
                    </i>
                    <OverlayTrigger
                      placement={mode !== "en" ? "right" : "left"}
                      overlay={<Tooltip>Demo Class</Tooltip>}
                    >
                      <i className="sidenav-mini-icon">
                        <AiOutlineAppstoreAdd />
                      </i>
                    </OverlayTrigger>
                    <span
                      className="item-name text-black"
                      style={{ fontWeight: "600" }}
                    >
                      Demo Class
                    </span>
                  </Link>
                </Nav.Item>
                {userType == "hr_assistant" ||
                userType == "hr_manager" ? null : (
                  <Nav.Item
                    as="li"
                    style={
                      location.pathname === `/teacher-badgeTier` ||
                      location.pathname.includes("/teacher-badgeTier")
                        ? { background: "#FFF0F5", borderRadius: "6px" }
                        : {}
                    }
                  >
                    <Link className={` nav-link`} to="/teacher-badgeTier">
                      <i className="icon">
                        <AiOutlineAppstoreAdd />
                      </i>
                      <OverlayTrigger
                        placement={mode !== "en" ? "right" : "left"}
                        overlay={<Tooltip>Upgrade</Tooltip>}
                      >
                        <i className="sidenav-mini-icon">
                          <AiOutlineAppstoreAdd />
                        </i>
                      </OverlayTrigger>
                      <span
                        className="item-name text-black"
                        style={{ fontWeight: "600" }}
                      >
                        Upgrade
                      </span>
                    </Link>
                  </Nav.Item>
                )}
              </ul>
            </Accordion.Collapse>
          </Accordion.Item>
        )}
        {permission[0]?.permission?.includes("Enrollment") && (
          <Accordion.Item
            as="li"
            eventKey="enrollment"
            bsPrefix={`nav-item `}
            onClick={() => setActive("enrollment")}
          >
            <CustomToggle
              eventKey="enrollment"
              onClick={(activeKey) => setActiveMenu(activeKey)}
            >
              <OverlayTrigger
                placement={mode !== "en" ? "right" : "left"}
                overlay={<Tooltip>Enrollment</Tooltip>}
              >
                <i className="icon">
                  {/* Change  */}
                  <GiSchoolBag className="text-black" />
                </i>
              </OverlayTrigger>
              <span
                className="item-name text-black"
                style={{ fontWeight: "600" }}
              >
                Enrollment
              </span>
              <i className="right-icon">
                <RightArrow />
              </i>
            </CustomToggle>
            <Accordion.Collapse eventKey="enrollment">
              <ul className="sub-nav">
                <Nav.Item
                  as="li"
                  style={
                    location.pathname === `/enrollment/course-list` ||
                    location.pathname.includes("/enrollment/course-list")
                      ? { background: "#FFF0F5", borderRadius: "6px" }
                      : {}
                  }
                >
                  <Link className={` nav-link`} to="/enrollment/course-list">
                    <i className="icon svg-icon">
                      <AiOutlineAppstoreAdd />
                    </i>
                    <OverlayTrigger
                      placement={mode !== "en" ? "right" : "left"}
                      overlay={<Tooltip>Courses</Tooltip>}
                    >
                      <i className="sidenav-mini-icon">
                        <AiOutlineAppstoreAdd />
                      </i>
                    </OverlayTrigger>
                    <span
                      className="item-name text-black"
                      style={{ fontWeight: "600" }}
                    >
                      Courses
                    </span>
                  </Link>
                </Nav.Item>

                <Nav.Item
                  as="li"
                  style={
                    location.pathname === `/enrollment/demo-class` ||
                    location.pathname.includes("/enrollment/demo-class")
                      ? { background: "#FFF0F5", borderRadius: "6px" }
                      : {}
                  }
                >
                  <Link className={` nav-link`} to="/enrollment/demo-class">
                    <i className="icon">
                      <AiOutlineAppstoreAdd />
                    </i>
                    <OverlayTrigger
                      placement={mode !== "en" ? "right" : "left"}
                      overlay={<Tooltip>Demo Class</Tooltip>}
                    >
                      <i className="sidenav-mini-icon">
                        <AiOutlineAppstoreAdd />
                      </i>
                    </OverlayTrigger>
                    <span
                      className="item-name text-black"
                      style={{ fontWeight: "600" }}
                    >
                      Demo Class
                    </span>
                  </Link>
                </Nav.Item>
                <Nav.Item
                  as="li"
                  style={
                    location.pathname === `/enrollment/tuition-request` ||
                    location.pathname.includes("/enrollment/tuition-request")
                      ? { background: "#FFF0F5", borderRadius: "6px" }
                      : {}
                  }
                >
                  <Link
                    className={` nav-link`}
                    to="/enrollment/tuition-request"
                  >
                    <i className="icon">
                      <AiOutlineAppstoreAdd />
                    </i>
                    <OverlayTrigger
                      placement={mode !== "en" ? "right" : "left"}
                      overlay={<Tooltip>Tuition Request</Tooltip>}
                    >
                      <i className="sidenav-mini-icon">
                        <AiOutlineAppstoreAdd />
                      </i>
                    </OverlayTrigger>
                    <span
                      className="item-name text-black"
                      style={{ fontWeight: "600" }}
                    >
                      Tuition Request
                    </span>
                  </Link>
                </Nav.Item>
                <Nav.Item
                  as="li"
                  style={
                    location.pathname === `/enrollments/tuition` ||
                    location.pathname.includes("/enrollments/tuition")
                      ? { background: "#FFF0F5", borderRadius: "6px" }
                      : {}
                  }
                >
                  <Link className={` nav-link`} to="/enrollments/tuition">
                    <i className="icon">
                      <AiOutlineAppstoreAdd />
                    </i>
                    <OverlayTrigger
                      placement={mode !== "en" ? "right" : "left"}
                      overlay={<Tooltip>Tuition</Tooltip>}
                    >
                      <i className="sidenav-mini-icon">
                        <AiOutlineAppstoreAdd />
                      </i>
                    </OverlayTrigger>
                    <span
                      className="item-name text-black"
                      style={{ fontWeight: "600" }}
                    >
                      Tuition
                    </span>
                  </Link>
                </Nav.Item>
              </ul>
            </Accordion.Collapse>
          </Accordion.Item>
        )}
        {permission[0]?.permission?.includes("Courses") && (
          <li
            style={
              location.pathname.includes("/courses")
                ? { background: "#AB0D47", borderRadius: "6px" }
                : {}
            }
            className={` nav-item `}
          >
            <Link className={`nav-link`} aria-current="page" to="/courses">
              <OverlayTrigger
                placement={mode === "ltr" ? "right" : "left"}
                overlay={<Tooltip>Courses</Tooltip>}
              >
                <i className="icon">
                  <svg
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      opacity="0.4"
                      d="M9.71701 0.333252H11.9743C12.9092 0.333252 13.6667 1.09715 13.6667 2.03989V4.31627C13.6667 5.25901 12.9092 6.02291 11.9743 6.02291H9.71701C8.78216 6.02291 8.02466 5.25901 8.02466 4.31627V2.03989C8.02466 1.09715 8.78216 0.333252 9.71701 0.333252Z"
                      fill={
                        location.pathname.includes("/courses")
                          ? "#fff"
                          : "black"
                      }
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M2.02569 0.333252H4.283C5.21785 0.333252 5.97535 1.09715 5.97535 2.03989V4.31627C5.97535 5.25901 5.21785 6.02291 4.283 6.02291H2.02569C1.09085 6.02291 0.333344 5.25901 0.333344 4.31627V2.03989C0.333344 1.09715 1.09085 0.333252 2.02569 0.333252ZM2.02569 7.97693H4.283C5.21785 7.97693 5.97535 8.74083 5.97535 9.68357V11.9599C5.97535 12.902 5.21785 13.6666 4.283 13.6666H2.02569C1.09085 13.6666 0.333344 12.902 0.333344 11.9599V9.68357C0.333344 8.74083 1.09085 7.97693 2.02569 7.97693ZM11.9743 7.97693H9.71702C8.78217 7.97693 8.02467 8.74083 8.02467 9.68357V11.9599C8.02467 12.902 8.78217 13.6666 9.71702 13.6666H11.9743C12.9092 13.6666 13.6667 12.902 13.6667 11.9599V9.68357C13.6667 8.74083 12.9092 7.97693 11.9743 7.97693Z"
                      fill={
                        location.pathname.includes("/courses")
                          ? "#fff"
                          : "black"
                      }
                    />
                  </svg>
                </i>
              </OverlayTrigger>
              <span
                className={`item-name ${
                  location.pathname.includes("/courses")
                    ? "text-white"
                    : "text-black"
                }`}
                style={{ fontWeight: "600" }}
              >
                Courses
              </span>
            </Link>
          </li>
        )}
        {permission[0]?.permission?.includes("Staff") && (
          <Accordion.Item
            as="li"
            eventKey="staff"
            bsPrefix={`nav-item `}
            onClick={() => setActive("staff")}
          >
            <CustomToggle
              eventKey="staff"
              onClick={(activeKey) => setActiveMenu(activeKey)}
            >
              <OverlayTrigger
                placement={mode !== "en" ? "right" : "left"}
                overlay={<Tooltip>Staff</Tooltip>}
              >
                <i className="icon">
                  <FiUsers className="text-black" />
                </i>
              </OverlayTrigger>
              <span
                className="item-name text-black"
                style={{ fontWeight: "600" }}
              >
                Staff
              </span>
              <i className="right-icon">
                <RightArrow />
              </i>
            </CustomToggle>
            <Accordion.Collapse eventKey="staff">
              <ul className="sub-nav">
                <Nav.Item
                  as="li"
                  style={
                    location.pathname === `/staffs/staff-list` ||
                    location.pathname.includes("/staffs/attendance")
                      ? { background: "#FFF0F5", borderRadius: "6px" }
                      : {}
                  }
                >
                  <Link className={` nav-link`} to="/staffs/staff-list">
                    <i className="icon">
                      <AiOutlineAppstoreAdd />
                    </i>
                    <OverlayTrigger
                      placement={mode !== "en" ? "right" : "left"}
                      overlay={<Tooltip>Staffs List</Tooltip>}
                    >
                      <i className="sidenav-mini-icon">
                        <AiOutlineAppstoreAdd />
                      </i>
                    </OverlayTrigger>
                    <span
                      className="item-name text-black"
                      style={{ fontWeight: "600" }}
                    >
                      Staffs List
                    </span>
                  </Link>
                </Nav.Item>

                <Nav.Item
                  as="li"
                  style={
                    location.pathname === `/staff-reports`
                      ? { background: "#FFF0F5", borderRadius: "6px" }
                      : {}
                  }
                >
                  <Link className={` nav-link`} to="/staff-reports">
                    <i className="icon">
                      <AiOutlineAppstoreAdd />
                    </i>
                    <OverlayTrigger
                      placement={mode !== "en" ? "right" : "left"}
                      overlay={<Tooltip>Reports</Tooltip>}
                    >
                      <i className="sidenav-mini-icon">
                        <AiOutlineAppstoreAdd />
                      </i>
                    </OverlayTrigger>
                    <span
                      className="item-name text-black"
                      style={{ fontWeight: "600" }}
                    >
                      Reports
                    </span>
                  </Link>
                </Nav.Item>
              </ul>
            </Accordion.Collapse>
          </Accordion.Item>
        )}
        {permission[0]?.permission?.includes("Users List") && (
          <li
            style={
              location.pathname.includes("/users")
                ? { background: "#AB0D47", borderRadius: "6px" }
                : {}
            }
            className={` nav-item `}
          >
            <Link
              className={`nav-link`}
              aria-current="page"
              to="/users"
            >
              <OverlayTrigger
                placement={mode === "ltr" ? "right" : "left"}
                overlay={<Tooltip>Users</Tooltip>}
              >
                <i className="sidenav-mini-icon">
                  {location.pathname.includes("/users") ? (
                    <FiUser color="#fff" />
                  ) : (
                    <FiUser color="#000" />
                  )}
                </i>
              </OverlayTrigger>
              {location.pathname.includes("/users") ? (
                <FiUser color="#fff" />
              ) : (
                <FiUser color="#000" />
              )}
              <span
                className={`item-name ${
                  location.pathname.includes("/users")
                    ? "text-white"
                    : "text-black"
                }`}
                style={{ fontWeight: "600" }}
              >
                Users
              </span>
            </Link>
          </li>
        )}
        {permission[0]?.permission?.includes("Crm") && (
          <li
            style={
              location.pathname.includes("/crm")
                ? { background: "#AB0D47", borderRadius: "6px" }
                : {}
            }
            className={` nav-item `}
          >
            <Link
              className={`nav-link`}
              aria-current="page"
              to="/crm/leads-list"
            >
              {location.pathname.includes("/crm") ? (
                <SiCivicrm color="#fff" />
              ) : (
                <SiCivicrm color="#000" />
              )}
              <OverlayTrigger
                placement={mode === "ltr" ? "right" : "left"}
                overlay={<Tooltip>Crm</Tooltip>}
              >
                <i className="sidenav-mini-icon">
                  {location.pathname.includes("/crm") ? (
                    <SiCivicrm color="#fff" />
                  ) : (
                    <SiCivicrm color="#000" />
                  )}
                </i>
              </OverlayTrigger>
              <span
                className={`item-name ${
                  location.pathname.includes("/crm")
                    ? "text-white"
                    : "text-black"
                }`}
                style={{ fontWeight: "600" }}
              >
                Crm
              </span>
            </Link>
          </li>
        )}
        {permission[0]?.permission?.includes("Transaction") && (
          <Accordion.Item
            as="li"
            eventKey="transactions"
            bsPrefix={`nav-item `}
            onClick={() => setActive("transactions")}
          >
            <CustomToggle
              eventKey="transactions"
              onClick={(activeKey) => setActiveMenu(activeKey)}
            >
              <OverlayTrigger
                placement={mode !== "en" ? "right" : "left"}
                overlay={<Tooltip>Transactions</Tooltip>}
              >
                <i className="icon">
                  <IoSchoolSharp className="text-black" />
                </i>
              </OverlayTrigger>
              <span
                className="item-name text-black"
                style={{ fontWeight: "600" }}
              >
                Transactions
              </span>
              <i className="right-icon">
                <RightArrow />
              </i>
            </CustomToggle>
            <Accordion.Collapse eventKey="transactions">
              <ul className="sub-nav">
                <Nav.Item
                  as="li"
                  style={location.pathname === `/transaction`                   
                      ? { background: "#FFF0F5", borderRadius: "6px" }
                      : {}
                  }
                >
                  <Link className={` nav-link`} to="/transactions/income">
                    <i className="icon">
                      <AiOutlineAppstoreAdd />
                    </i>
                    <OverlayTrigger
                      placement={mode !== "en" ? "right" : "left"}
                      overlay={<Tooltip>Transactions List</Tooltip>}
                    >
                      <i className="sidenav-mini-icon">
                        <AiOutlineAppstoreAdd />
                      </i>
                    </OverlayTrigger>
                    <span
                      className="item-name text-black"
                      style={{ fontWeight: "600" }}
                    >
                      Transactions List
                    </span>
                  </Link>
                </Nav.Item>

                <Nav.Item
                  as="li"
                  style={
                    location.pathname === `/transaction-report`
                      ? { background: "#FFF0F5", borderRadius: "6px" }
                      : {}
                  }
                >
                  <Link className={` nav-link`} to="/transaction-report">
                    <i className="icon">
                      <AiOutlineAppstoreAdd />
                    </i>
                    <OverlayTrigger
                      placement={mode !== "en" ? "right" : "left"}
                      overlay={<Tooltip>Reports</Tooltip>}
                    >
                      <i className="sidenav-mini-icon">
                        <AiOutlineAppstoreAdd />
                      </i>
                    </OverlayTrigger>
                    <span
                      className="item-name text-black"
                      style={{ fontWeight: "600" }}
                    >
                      Reports
                    </span>
                  </Link>
                </Nav.Item>
              </ul>
            </Accordion.Collapse>
          </Accordion.Item>        
        )}
        {permission[0]?.permission?.includes("Support") && (
          <li
            style={
              location.pathname.includes("/contact-support")
                ? { background: "#AB0D47", borderRadius: "6px" }
                : {}
            }
            className={` nav-item `}
          >
            <Link
              className={`nav-link`}
              aria-current="page"
              to="/contact-support"
            >
              <OverlayTrigger
                placement={mode === "ltr" ? "right" : "left"}
                overlay={<Tooltip>Support</Tooltip>}
              >
                <i className="sidenav-mini-icon">
                  {location.pathname.includes("/contact-support") ? (
                    <BiSupport color="#fff" />
                  ) : (
                    <BiSupport color="#000" />
                  )}
                </i>
              </OverlayTrigger>
              {location.pathname.includes("/contact-support") ? (
                <BiSupport color="#fff" />
              ) : (
                <BiSupport color="#000" />
              )}
              <span
                className={`item-name ${
                  location.pathname.includes("/contact-support")
                    ? "text-white"
                    : "text-black"
                }`}
                style={{ fontWeight: "600" }}
              >
                Support
              </span>
            </Link>
          </li>
        )}
        {permission[0]?.permission?.includes("Payroll") && (
          <li
            style={
              location.pathname.includes("/payroll")
                ? { background: "#AB0D47", borderRadius: "6px" }
                : {}
            }
            className={` nav-item `}
          >
            <Link
              className={`nav-link`}
              aria-current="page"
              to="/payroll/staff"
            >
              <OverlayTrigger
                placement={mode === "ltr" ? "right" : "left"}
                overlay={<Tooltip>Payroll</Tooltip>}
              >
                <i className="sidenav-mini-icon">
                  {location.pathname.includes("/payroll") ? (
                    <MdPayment color="#fff" />
                  ) : (
                    <MdPayment color="#000" />
                  )}
                </i>
              </OverlayTrigger>
              {location.pathname.includes("/payroll") ? (
                <MdPayment color="#fff" />
              ) : (
                <MdPayment color="#000" />
              )}
              <span
                className={`item-name ${
                  location.pathname.includes("/payroll")
                    ? "text-white"
                    : "text-black"
                }`}
                style={{ fontWeight: "600" }}
              >
                Payroll
              </span>
            </Link>
          </li>
        )}
        {permission[0]?.permission?.includes("Profile") && (
          <li
            style={
              location.pathname.includes("/profile")
                ? { background: "#AB0D47", borderRadius: "6px" }
                : {}
            }
            className={` nav-item `}
          >
            <Link
              className={`nav-link`}
              aria-current="page"
              to="/profile/user-payroll"
            >
              <OverlayTrigger
                placement={mode === "ltr" ? "right" : "left"}
                overlay={<Tooltip>Profile</Tooltip>}
              >
                <i className="sidenav-mini-icon">
                  {location.pathname.includes("/profile") ? (
                    <FiUser color="#fff" />
                  ) : (
                    <FiUser color="#000" />
                  )}
                </i>
              </OverlayTrigger>
              {location.pathname.includes("/profile") ? (
                <FiUser color="#fff" />
              ) : (
                <FiUser color="#000" />
              )}
              <span
                className={`item-name ${
                  location.pathname.includes("/profile")
                    ? "text-white"
                    : "text-black"
                }`}
                style={{ fontWeight: "600" }}
              >
                Profile
              </span>
            </Link>
          </li>
        )}
        {permission[0]?.permission?.includes("Settings") && (
          <Accordion.Item
            as="li"
            eventKey="settings"
            bsPrefix={`nav-item `}
            onClick={() => setActive("settings")}
          >
            <CustomToggle
              eventKey="settings"
              onClick={(activeKey) => setActiveMenu(activeKey)}
            >
              <OverlayTrigger
                placement={mode !== "en" ? "right" : "left"}
                overlay={<Tooltip>Settings</Tooltip>}
              >
                <i className="icon">
                  <BsFillBox2Fill className="text-black" />
                </i>
              </OverlayTrigger>
              <span
                className="item-name text-black"
                style={{ fontWeight: "600" }}
              >
                Settings
              </span>
              <i className="right-icon">
                <RightArrow />
              </i>
            </CustomToggle>
            <Accordion.Collapse eventKey="settings">
              <ul className="sub-nav">
                <Nav.Item
                  as="li"
                  style={
                    location.pathname === `/settings/generalsettings` ||
                    location.pathname.includes("/settings/generalsettings")
                      ? { background: "#FFF0F5", borderRadius: "6px" }
                      : {}
                  }
                >
                  <Link
                    className={` nav-link`}
                    to="/settings/generalsettings/default"
                  >
                    <i className="icon svg-icon">
                      <AiOutlineAppstoreAdd />
                    </i>
                    <OverlayTrigger
                      placement={mode !== "en" ? "right" : "left"}
                      overlay={<Tooltip>General Settings</Tooltip>}
                    >
                      <i className="sidenav-mini-icon">
                        <AiOutlineAppstoreAdd />
                      </i>
                    </OverlayTrigger>
                    <span
                      className="item-name text-black"
                      style={{ fontWeight: "600" }}
                    >
                      General Settings
                    </span>
                  </Link>
                </Nav.Item>

                <Nav.Item
                  as="li"
                  style={
                    location.pathname === `/settings/studsettings` ||
                    location.pathname.includes("/settings/studsettings")
                      ? { background: "#FFF0F5", borderRadius: "6px" }
                      : {}
                  }
                >
                  <Link
                    className={` nav-link`}
                    to="/settings/studsettings/status"
                  >
                    <i className="icon svg-icon">
                      <AiOutlineAppstoreAdd />
                    </i>
                    <OverlayTrigger
                      placement={mode !== "en" ? "right" : "left"}
                      overlay={<Tooltip>Student Settings</Tooltip>}
                    >
                      <i className="sidenav-mini-icon">
                        <AiOutlineAppstoreAdd />
                      </i>
                    </OverlayTrigger>
                    <span
                      className="item-name text-black"
                      style={{ fontWeight: "600" }}
                    >
                      Student Settings
                    </span>
                  </Link>
                </Nav.Item>

                <Nav.Item
                  as="li"
                  style={
                    location.pathname === `/settings/professor` ||
                    location.pathname.includes("/settings/professor")
                      ? { background: "#FFF0F5", borderRadius: "6px" }
                      : {}
                  }
                >
                  <Link
                    className={` nav-link`}
                    to="/settings/professor/default"
                  >
                    <i className="icon">
                      <AiOutlineAppstoreAdd />
                    </i>
                    <OverlayTrigger
                      placement={mode !== "en" ? "right" : "left"}
                      overlay={<Tooltip>Teacher Settings</Tooltip>}
                    >
                      <i className="sidenav-mini-icon">
                        <AiOutlineAppstoreAdd />
                      </i>
                    </OverlayTrigger>
                    <span
                      className="item-name text-black"
                      style={{ fontWeight: "600" }}
                    >
                      Teacher Settings
                    </span>
                  </Link>
                </Nav.Item>
                <Nav.Item
                  as="li"
                  style={
                    location.pathname === `/settings/workforce` ||
                    location.pathname.includes("/settings/workforce")
                      ? { background: "#FFF0F5", borderRadius: "6px" }
                      : {}
                  }
                >
                  <Link
                    className={` nav-link`}
                    to="/settings/workforce/designation"
                  >
                    <i className="icon">
                      <AiOutlineAppstoreAdd />
                    </i>
                    <OverlayTrigger
                      placement={mode !== "en" ? "right" : "left"}
                      overlay={<Tooltip>Staff Settings</Tooltip>}
                    >
                      <i className="sidenav-mini-icon">
                        <AiOutlineAppstoreAdd />
                      </i>
                    </OverlayTrigger>
                    <span
                      className="item-name text-black"
                      style={{ fontWeight: "600" }}
                    >
                      Staff Settings
                    </span>
                  </Link>
                </Nav.Item>
                <Nav.Item
                  as="li"
                  style={
                    location.pathname === `/settings/payment` ||
                    location.pathname.includes("/settings/payment")
                      ? { background: "#FFF0F5", borderRadius: "6px" }
                      : {}
                  }
                >
                  <Link
                    className={` nav-link`}
                    to="/settings/payment/categories"
                  >
                    <i className="icon">
                      <AiOutlineAppstoreAdd />
                    </i>
                    <OverlayTrigger
                      placement={mode !== "en" ? "right" : "left"}
                      overlay={<Tooltip>Transaction Settings</Tooltip>}
                    >
                      <i className="sidenav-mini-icon">
                        <AiOutlineAppstoreAdd />
                      </i>
                    </OverlayTrigger>
                    <span
                      className="item-name text-black"
                      style={{ fontWeight: "600" }}
                    >
                      Transaction Settings
                    </span>
                  </Link>
                </Nav.Item>
              </ul>
            </Accordion.Collapse>
          </Accordion.Item>
        )}
        {/* <li
            style={
              location.pathname.includes("/search")
                ? { background: "#AB0D47", borderRadius: "6px" }
                : {}
            }
            className={` nav-item `}
          >
            <Link
              className={`nav-link`}
              aria-current="page"
              to="/search"
            >
              {location.pathname.includes("/search") ? (
                <IoMdSearch color="#fff" />
              ) : (
                <IoMdSearch color="#000" />
              )}
              <OverlayTrigger
                placement={mode === "ltr" ? "right" : "left"}
                overlay={<Tooltip>Search</Tooltip>}
              >
                <i className="sidenav-mini-icon">
                  {location.pathname.includes("/search") ? (
                    <IoMdSearch color="#fff" />
                  ) : (
                    <IoMdSearch color="#000" />
                  )}
                </i>
              </OverlayTrigger>
              <span
                className={`item-name ${
                  location.pathname.includes("/search")
                    ? "text-white"
                    : "text-black"
                }`}
                style={{ fontWeight: "600" }}
              >
                Search
              </span>
            </Link>
          </li> */}
      </Accordion>
    </Fragment>
  );
});

VerticalNav.displayName = "VerticalNav";
export default VerticalNav;
