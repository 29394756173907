import { useMutation, useQuery, useQueryClient } from "react-query";
import { editNotificationDetails, getNotificationCount, getNotifications } from "./notificationUrls"

const get_notifications = (query) => {
    return useQuery(["getNotifications", query], () => getNotifications(query));
};

const get_notification_count = () => {
    return useQuery(["getNotificationsCount"], () => getNotificationCount());
};
  
const read_notification = () => {
    const queryClient = useQueryClient();
    return useMutation((newData) => editNotificationDetails(newData), {
      onMutate: async (addData) => {
        await queryClient.cancelQueries("getNotifications");
        const prev = queryClient.getQueryData("getNotifications");
        return {
          prev,
          addData,
        };
      },
      onSettled: () => {
        queryClient.invalidateQueries("getNotifications");
      },
      onError: (err, _context) => {
        queryClient.setQueryData("getNotifications", _context.prev);
      },
    });
  };

const useNotificationQuery = {
    get_notifications,
    read_notification,
    get_notification_count
};

export default useNotificationQuery;