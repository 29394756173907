import DataService from "../DataService";

const getNotifications = async ({ limit, offset }) => {
  let url = `/admin/notifications/?limit=${limit}&offset=${offset}`;
  const data = await DataService.get(url);
  return data?.data?.data;
};

const getNotificationCount = async () => {
    let url = `/admin/notification/unread/count/`;
    const data = await DataService.get(url);
    return data?.data;
}; 

const editNotificationDetails = async (values) => {
    const { id } = values;
    let url = `/admin/notification/${id}/`;
    const data = await DataService.patch(url, values);
    return data.data;
  };

export { getNotifications, editNotificationDetails  ,getNotificationCount};