import DataService from "../DataService";

const getStudentSearchList = async ({search}) => {
  let url = `admin/student/list/`;
  if (search && search?.length > 0) {
    url = url + `?search=${search}`;
  }
  const data = await DataService.get(url);
  return data.data.data;
};

const getCountriesList = async () => {
  let url = `common/countries/?limit=200`;
  const data = await DataService.get(url);
  return data.data;
};

const getStatesList = async (id) => {
  let url = `common/states/?country=${id}`;
  const data = await DataService.get(url);
  return data?.data;
};

const getContactSupport = async ({ limit, offset }) => {
  let url = `admin/contact_supports/?limit=${limit}&offset=${offset}`;
  const data = await DataService.get(url);
  return data?.data;
};

const getContactSupportById = async (id) => {
  let url = `/admin/contact_support/${id}/`;
  const data = await DataService.get(url);
  return data?.data;
};

const getDistrictList = async (id) => {
  let url = `common/districts/?state=${id}`;
  const data = await DataService.get(url);
  return data?.data;
};

const getCities = async (id) => {
  let url = `/common/cities/?state=${id}`;
  const data = await DataService.get(url);
  return data?.data;
};

const getCurrencyList = async (id) => {
  let url = `common/currencies/?limit=200`;
  const data = await DataService.get(url);
  return data.data;
};

const getTimeZone = async ({ search, country, limit, offset }) => {
  let url = `common/time_zones/?limit=${limit}&offset=${offset}`;
  const data = await DataService.get(url);
  return data.data;
};

const editContactSupport = async (values) => {
  const { id } = values;
  let url = `/admin/contact_support/${id}/`;
  const data = await DataService.patch(url, values);
  return data?.data;
};

const deleteContactSupport = async ({ id }) => {
  let url = `/admin/contact_support/${id}/`;
  const data = await DataService.del(url);
  return data?.data;
};

const getCheckinStatus = async () => {
  let url = `admin/check-in-out/`;
  const data = await DataService.get(url);
  return data?.data;
};

const toggleCheckin = async () => {
  let url = `admin/check-in-out/`;
  const data = await DataService.post(url);
  return data?.data;
};



const getMyAttendanceDetails = async ({
  limit,
  offset,
  filter_type,
  start_date,
  end_date,
}) => {
  let url = `admin/my/attendances/?limit=${limit}&offset=${offset}`;
  if (filter_type) {
    url = `admin/my/attendances/?filter_type=${filter_type}&limit=${limit}&offset=${offset}`;
  }
  if (end_date) {
    url = url + `&end_date=${end_date}`;
  }
  if (start_date) {
    url = url + `&start_date=${start_date}`;
  }
  const data = await DataService.get(url);
  return data?.data;
};

const sendSupportRequest = async ({ id }) => {
  let url = `/admin/support/${id}/student_course_requests/`;
  const data = await DataService.post(url, {});
  return data?.data;
};

const getDashboardData = async () => {
  let url = `/admin/dashboard/`;
  const data = await DataService.get(url);
  return data?.data;
};

const getNoticeBoardDetails = async () => {
  let url = `/admin/announcements/`;
  const data = await DataService.get(url);
  return data?.data;
};

const getUsersList = async ({ limit, offset ,search }) => {
  let url = `admin/users/?limit=${limit}&offset=${offset}&search=${search}`;
  const data = await DataService.get(url);
  return data?.data;
};

const deleteUser = async ({id}) => {
  console.log(id)
  let url = `/admin/user/${id}/`;
  const data = await DataService.del(url);
  return data?.data;
};

export {
  getStudentSearchList,
  getCountriesList,
  getContactSupport,
  getStatesList,
  getCurrencyList,
  getTimeZone,
  getDistrictList,
  getCities,
  editContactSupport,
  getContactSupportById,
  deleteContactSupport,
  getCheckinStatus,
  toggleCheckin,
  getMyAttendanceDetails,
  sendSupportRequest,
  getDashboardData,
  getNoticeBoardDetails,
  getUsersList,
  deleteUser
};
