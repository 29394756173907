import { useState, useEffect, memo } from "react";
import { toast } from "react-toastify";

import { Navbar, Container, Nav, Dropdown, Form ,Card} from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { Link, useLocation } from "react-router-dom";

import { useTranslation } from "react-i18next";

import CustomToggle from "../../../dropdowns";

import { useDispatch, useSelector } from "react-redux";

import Logo from "../../components/logo";
import Profile from "../../../../assets/images/Profile.svg"

import * as SettingSelector from "../../../../store/setting/selectors";

import { theme_scheme_direction } from "../../../../store/setting/reducers";

import RadioBtn from "../../../setting/elements/radio-btn";
import useUtilQuery from "../../../../store/util/useUtilQuery";
import useNotificationQuery from "../../../../store/notifications/useNotificationQuery";

const Headerpro = memo((props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const themeFontSize = useSelector(SettingSelector.theme_font_size);
  const headerNavbar = useSelector(SettingSelector.header_navbar);
  const mode = useSelector(SettingSelector.theme_scheme_direction);
  const [search , setSearch] = useState('')
  const [loading, setLoading] = useState(false);

  const [lang, setLang] = useState(mode === "rtl" ? "ab" : "en");

  const { i18n, t } = useTranslation();

  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
  };

  useEffect(() => {
    if (lang === "en") {
      dispatch(theme_scheme_direction("ltr"));
      changeLanguage("en");
    } else if (lang === "ab") {
      dispatch(theme_scheme_direction("rtl"));
      changeLanguage("ab");
    }
  }, [lang]);

  const { data: checkinStatus } = useUtilQuery.get_checkin_status();
  const { data: studentList } = useUtilQuery.get_student_search_list({search});
  const { data: notifications } = useNotificationQuery.get_notifications({limit:6,offset:0});
  const { data: notificationCount } = useNotificationQuery.get_notification_count();

  const {
    mutateAsync: toggleCheckin,
    isError,
    error,
    isSuccess,
  } = useUtilQuery.update_checkin_status();

  useEffect(() => {
    if (headerNavbar === "navs-sticky" || headerNavbar === "nav-glass") {
      window.onscroll = () => {
        if (document.documentElement.scrollTop > 50) {
          document.getElementsByTagName("nav")[0].classList.add("menu-sticky");
        } else {
          document
            .getElementsByTagName("nav")[0]
            .classList.remove("menu-sticky");
        }
      };
    }

    document.getElementsByTagName("html")[0].classList.add(themeFontSize);

    const result = window.matchMedia("(max-width: 1200px)");
    window.addEventListener("resize", () => {
      if (result.matches === true) {
        if (show1 === true) {
          document.documentElement.style.setProperty("overflow", "hidden");
        } else {
          document.documentElement.style.removeProperty("overflow");
        }
      } else {
        document.documentElement.style.removeProperty("overflow");
      }
    });
    if (window.innerWidth <= "1200") {
      if (show1 === true) {
        document.documentElement.style.setProperty("overflow", "hidden");
      } else {
        document.documentElement.style.removeProperty("overflow");
      }
    } else {
      document.documentElement.style.removeProperty("overflow");
    }
  });

  const logout = () => {
    localStorage.clear();
    history.push("/auth/sign-in");
  };
  const [show, setShow] = useState(true);

  const [show1, setShow1] = useState(false);

  const fullscreen = () => {
    if (
      !document.fullscreenElement &&
      !document.mozFullScreenElement &&
      !document.webkitFullscreenElement &&
      !document.msFullscreenElement
    ) {
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT
        );
      } else if (document.documentElement.msRequestFullscreen) {
        document.documentElement.msRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT
        );
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
    }
  };

  const minisidebar = () => {
    document.getElementsByTagName("ASIDE")[0].classList.toggle("sidebar-mini");
  };

  let location = useLocation();

  const handleCheckin = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      await toggleCheckin();
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isError) {
      toast.error(error.data?.message);
    }
  }, [isError, error]);


  return (
    <Navbar expand="xl" className={`nav iq-navbar ${headerNavbar} `}>
      <Container fluid className="navbar-inner">
        <Link to="#" className="navbar-brand">
          <Logo color={true} />
        </Link>
        <div
          className="sidebar-toggle"
          data-toggle="sidebar"
          data-active="true"
          onClick={minisidebar}
        >
          <i className="icon d-flex">
            <svg width="20px" viewBox="0 0 24 24" className="icon-20">
              <path
                fill="currentColor"
                d="M4,11V13H16L10.5,18.5L11.92,19.92L19.84,12L11.92,4.08L10.5,5.5L16,11H4Z"
              />
            </svg>
          </i>
        </div>
        <div className="d-flex align-items-center justify-content-between product-offcanvas">
          <div className="breadcrumb-title border-end me-3 pe-3 d-none d-xl-block">
            <small className="mb-0 text-capitalize">{`${
              location.pathname === "/dashboard" ? "Dashboard" : ""
            }`}</small>
          </div>
          <div
            className={`offcanvas-backdrop fade  ${
              show1 === true ? "show d-block" : "d-none"
            }`}
            onClick={() => setShow1(false)}
          ></div>
        </div>
        <div
          className={` navbar-collapse ${
            show1 === true ? "collapse show" : "collapse"
          }`}
          id="navbarSupportedContent"
        >
          <ul className="mb-2 navbar-nav ms-auto align-items-center navbar-list mb-lg-0">
            <Dropdown as="li" className="nav-item border-end d-none d-xl-block">
              <Dropdown.Toggle as={CustomToggle} className="btn btn-sm bg-body">
                <div
                  className="form-group input-group mb-0 search-input"
                  style={{ width: "40rem" }}
                >
                  <Form.Control
                    type="search"
                    placeholder="Search student..."
                    onChange={(e) => setSearch(e.target.value)}
                  />
                  <span className="input-group-text">
                    <svg
                      width="20"
                      className="icon-20"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle
                        circle
                        cx="11.7669"
                        cy="11.7666"
                        r="8.98856"
                        stroke="black"
                        strokeWidth="1.5"
                        strokeLinejoin="round"
                      ></circle>
                      <path
                        d="M18.0186 18.4851L21.5426 22"
                        stroke="black"
                        strokeWidth="1.5"
                        strokeLinejoin="round"
                      ></path>
                    </svg>
                  </span>
                </div>
              </Dropdown.Toggle>
              <Dropdown.Menu
                variant="end"
                aria-labelledby="navbarDropdown-search-11"
                style={{
                  width: "40rem",
                  maxHeight: "90vh",
                  overflowY: "scroll",
                  overflowX: "hidden",
                }}
              >
                {search !== "" &&
                  studentList &&
                  studentList?.results?.length > 0 &&
                  studentList?.results?.map((student, index) => (
                    <Card
                      as={Link}
                      to={`/student/${student?.id}/details`}
                      key={index}
                      className="p-0 mt-0 student-search-card"
                    >
                      <Card.Body className="pt-1 pb-3 d-flex justify-content-between align-items-center">
                        <div className="d-flex gap-2 align-items-center">
                          <div>
                            <Card.Img
                              variant="top"
                              src={Profile}
                              style={{ width: "45px", height: "45px" }}
                              className="rounded-circle"
                            />
                          </div>
                          <div className="text-start">
                            <Card.Title className="text-primary pt-2 text-bold">
                              {student?.first_name?.toUpperCase() || "--"}{" "}
                              {student?.last_name?.toUpperCase() || "--"}
                            </Card.Title>
                            <Card.Title style={{ fontSize: "13px" }}>
                              {student?.roseapp_id}
                            </Card.Title>
                          </div>
                        </div>
                        <div className="text-end">
                          <Card.Text
                            className="mt-2"
                            style={{ fontSize: "13px" }}
                          >
                            Mobile : {student?.mobile || "--"}
                          </Card.Text>
                          <Card.Text style={{ fontSize: "13px" }}>
                            Staff :{" "}
                            {student?.staff_name || (
                              <span className="text-danger">Not Assigned</span>
                            )}
                          </Card.Text>
                        </div>
                      </Card.Body>
                    </Card>
                  ))}
                {(search === "" || studentList?.results?.length === 0) && (
                  <div className="d-flex justify-content-center my-2">
                    No Data Found
                  </div>
                )}
              </Dropdown.Menu>
            </Dropdown>
            <Nav.Item className=" iq-full-screen d-none d-xl-block ms-2">
              {loading ? (
                <Nav.Link id="check-in" disabled>
                  <div className="btn btn-primary btn-icon btn-sm rounded-pill">
                    <span className="btn-inner">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        class="bi bi-three-dots"
                        viewBox="0 0 16 16"
                      >
                        <path d="M3 9.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z" />
                      </svg>
                    </span>
                  </div>
                </Nav.Link>
              ) : (
                <div id="check-in" className="me-5">
                  {checkinStatus?.data?.status ? (
                    <button
                      onClick={(e) => handleCheckin(e)}
                      className="btn btn-primary"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        class="bi bi-box-arrow-in-left"
                        viewBox="0 0 16 16"
                      >
                        <path
                          fillRule="evenodd"
                          d="M10 3.5a.5.5 0 0 0-.5-.5h-8a.5.5 0 0 0-.5.5v9a.5.5 0 0 0 .5.5h8a.5.5 0 0 0 .5-.5v-2a.5.5 0 0 1 1 0v2A1.5 1.5 0 0 1 9.5 14h-8A1.5 1.5 0 0 1 0 12.5v-9A1.5 1.5 0 0 1 1.5 2h8A1.5 1.5 0 0 1 11 3.5v2a.5.5 0 0 1-1 0v-2z"
                        />
                        <path
                          fillRule="evenodd"
                          d="M4.146 8.354a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H14.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3z"
                        />
                      </svg>
                      <span className="ms-2">Check Out</span>
                    </button>
                  ) : (
                    <button
                      onClick={(e) => handleCheckin(e)}
                      className="btn btn-primary"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        class="bi bi-box-arrow-in-right"
                        viewBox="0 0 16 16"
                      >
                        <path
                          fillRule="evenodd"
                          d="M6 3.5a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-2a.5.5 0 0 0-1 0v2A1.5 1.5 0 0 0 6.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-8A1.5 1.5 0 0 0 5 3.5v2a.5.5 0 0 0 1 0v-2z"
                        />
                        <path
                          fillRule="evenodd"
                          d="M11.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H1.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3z"
                        />
                      </svg>
                      <span className="ms-2">Check In</span>
                    </button>
                  )}
                </div>
              )}
            </Nav.Item>
            <li className="nav-item dropdown me-0 me-xl-3">
              <div
                className="d-flex align-items-center mr-2 iq-font-style"
                role="group"
                aria-label="First group"
              >
                <RadioBtn
                  btnName="theme_font_size"
                  labelclassName="  border-0 btn-icon btn-sm"
                  id="font-size-sm"
                  defaultChecked={themeFontSize}
                  value="theme-fs-sm"
                >
                  <span className="mb-0 h6" style={{ color: "inherit" }}>
                    A
                  </span>
                </RadioBtn>
                <RadioBtn
                  btnName="theme_font_size"
                  labelclassName="  border-0 btn-icon"
                  id="theme-fs-md"
                  defaultChecked={themeFontSize}
                  value="theme-fs-md"
                >
                  <span className="mb-0 h4" style={{ color: "inherit" }}>
                    A
                  </span>
                </RadioBtn>
                <RadioBtn
                  btnName="theme_font_size"
                  labelclassName="  border-0 btn-icon"
                  id="theme-fs-lg"
                  defaultChecked={themeFontSize}
                  value="theme-fs-lg"
                >
                  <span className="mb-0 h2" style={{ color: "inherit" }}>
                    A
                  </span>
                </RadioBtn>
              </div>
            </li>
            <Nav.Item
              className=" iq-full-screen d-none d-xl-block"
              onClick={() => setShow(!show)}
            >
              <Nav.Link id="btnFullscreen" onClick={fullscreen}>
                <div className="btn btn-primary btn-icon btn-sm rounded-pill">
                  <span className="btn-inner">
                    <svg
                      className={`normal-screen ${
                        show === true ? "" : "d-none"
                      } icon-24`}
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M18.5528 5.99656L13.8595 10.8961"
                        stroke="white"
                        strokeWidth="1.5"
                        strokeLinejoin="round"
                      ></path>
                      <path
                        d="M14.8016 5.97618L18.5524 5.99629L18.5176 9.96906"
                        stroke="white"
                        strokeWidth="1.5"
                        strokeLinejoin="round"
                      ></path>
                      <path
                        d="M5.8574 18.896L10.5507 13.9964"
                        stroke="white"
                        strokeWidth="1.5"
                        strokeLinejoin="round"
                      ></path>
                      <path
                        d="M9.60852 18.9164L5.85775 18.8963L5.89258 14.9235"
                        stroke="white"
                        strokeWidth="1.5"
                        strokeLinejoin="round"
                      ></path>
                    </svg>
                    <svg
                      className={`full-normal-screen ${
                        show === false ? "" : "d-none"
                      } icon-24`}
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M13.7542 10.1932L18.1867 5.79319"
                        stroke="white"
                        strokeWidth="1.5"
                        strokeLinejoin="round"
                      ></path>
                      <path
                        d="M17.2976 10.212L13.7547 10.1934L13.7871 6.62518"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinejoin="round"
                      ></path>
                      <path
                        d="M10.4224 13.5726L5.82149 18.1398"
                        stroke="white"
                        strokeWidth="1.5"
                        strokeLinejoin="round"
                      ></path>
                      <path
                        d="M6.74391 13.5535L10.4209 13.5723L10.3867 17.2755"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinejoin="round"
                      ></path>
                    </svg>
                  </span>
                </div>
              </Nav.Link>
            </Nav.Item>
            <Dropdown as="li" className="nav-item">
              <Dropdown.Toggle as={CustomToggle} variant="nav-link ">
                <div className="btn btn-primary btn-icon btn-sm rounded-pill btn-action">
                  <span className="btn-inner">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-bell"
                      viewBox="0 0 16 16"
                    >
                      <path d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2M8 1.918l-.797.161A4 4 0 0 0 4 6c0 .628-.134 2.197-.459 3.742-.16.767-.376 1.566-.663 2.258h10.244c-.287-.692-.502-1.49-.663-2.258C12.134 8.197 12 6.628 12 6a4 4 0 0 0-3.203-3.92zM14.22 12c.223.447.481.801.78 1H1c.299-.199.557-.553.78-1C2.68 10.2 3 6.88 3 6c0-2.42 1.72-4.44 4.005-4.901a1 1 0 1 1 1.99 0A5 5 0 0 1 13 6c0 .88.32 4.2 1.22 6" />
                    </svg>
                  </span>
                 {notificationCount?.data?.count > 0 && <span className="notification-alert"></span>}
                </div>
              </Dropdown.Toggle>
              <Dropdown.Menu variant="end p-0 sub-drop mt-3" style={{width:'450px'}}>
                <Card className="m-0 shadow-none ">
                  <Card.Header className="py-3 d-flex justify-content-between bg-primary">
                    <div className="header-title">
                      <h5 className="mb-0 text-white">Notifications - {notificationCount?.data?.count} unread </h5>
                    </div>
                  </Card.Header>
                  <Card.Body className="p-0 max-17 scroll-thin">
                    {notifications?.results?.length > 0 ?
                      notifications?.results?.map((item, index) => (
                      <div key={index} className="iq-sub-card" style={{cursor:'text'}}>
                        <div className="d-flex align-items-center">
                        <div className="ms-3 flex-grow-1">
                          <h6 className={item.status === 'read' ? 'text-muted mb-0' : 'text-primary mb-0'}>{item?.title}</h6>
                          <div className="d-flex justify-content-between align-items-center">
                            <small className="float-right font-size-12 mt-1">{item?.description}</small>
                          </div>
                        </div>
                      </div>
                    </div>)):<div className="p-3 text-center">No Notifications</div>}
                  </Card.Body>
                 { notifications?.results?.length > 0&&<Card.Footer className="p-0">
                    <div className="d-grid">
                      <Link
                        to="/notifications"
                        className="btn btn-primary"
                      >
                        View All
                      </Link>
                    </div>
                  </Card.Footer>}
                </Card>
              </Dropdown.Menu>
            </Dropdown>
            <Dropdown as="li" className="nav-item iq-tour ps-3 ps-lg-0">
              <Dropdown.Toggle
                as={CustomToggle}
                variant="py-0  d-flex align-items-center nav-link"
              >
                <div className="d-flex flex-row justify-content-center  btn btn-primary btn-icon btn-sm rounded-pill">
                  <span className="btn-inner">
                    <svg
                      width="32"
                      className="icon-32"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M11.997 15.1746C7.684 15.1746 4 15.8546 4 18.5746C4 21.2956 7.661 21.9996 11.997 21.9996C16.31 21.9996 19.994 21.3206 19.994 18.5996C19.994 15.8786 16.334 15.1746 11.997 15.1746Z"
                        fill="currentColor"
                      ></path>
                      <path
                        opacity="0.4"
                        d="M11.9971 12.5838C14.9351 12.5838 17.2891 10.2288 17.2891 7.29176C17.2891 4.35476 14.9351 1.99976 11.9971 1.99976C9.06008 1.99976 6.70508 4.35476 6.70508 7.29176C6.70508 10.2288 9.06008 12.5838 11.9971 12.5838Z"
                        fill="currentColor"
                      ></path>
                    </svg>
                  </span>
                </div>
              </Dropdown.Toggle>
              <Dropdown.Menu variant="end">
                {/* <Dropdown.Item href="/staff-profile">Profile</Dropdown.Item> */}
                <hr className="dropdown-divider" />
                <Dropdown.Item onClick={() => logout()}>Logout</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </ul>
        </div>
      </Container>
    </Navbar>
  );
});

Headerpro.displayName = "Headerpro";
export default Headerpro;
