import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  getCountriesList,
  getContactSupport,
  getStatesList,
  getCurrencyList,
  getTimeZone,
  getDistrictList,
  getCities,
  getContactSupportById,
  editContactSupport,
  deleteContactSupport,
  getCheckinStatus,
  toggleCheckin,
  getMyAttendanceDetails,
  sendSupportRequest,
  getDashboardData,
  getNoticeBoardDetails,
  getStudentSearchList,
  getUsersList,
  deleteUser,
} from "./utilUrls";

const get_student_search_list = (query) => {
  return useQuery(["studentList", query], () => getStudentSearchList(query));
};

const Contact_support = (value) => {
  return useQuery(
    ["contactSupportList", value],
    () => getContactSupport(value),
    {
      refetchOnWindowFocus: false,
      poll: 500,
    }
  );
};

const Countries_list = () => {
  return useQuery(["countriesList"], () => getCountriesList(), {
    refetchOnWindowFocus: false,
    poll: 500,
  });
};

const States_list = (id) => {
  return useQuery(["statesList", id], () => getStatesList(id), {
    refetchOnWindowFocus: false,
    enabled: id !== null && id !== undefined && id !== "Country" ? true : false,
  });
};

const Distrcit_list = (id) => {
  return useQuery(["districtList", id], () => getDistrictList(id), {
    refetchOnWindowFocus: false,
    enabled: id !== null && id !== undefined ? true : false,
  });
};

const cities_list = (id) => {
  return useQuery(["city", id], () => getCities(id), {
    refetchOnWindowFocus: false,
    enabled: id !== null ? true : false,
  });
};

const Currencies_list = () => {
  return useQuery(["currenciesList"], () => getCurrencyList(), {
    refetchOnWindowFocus: false,
  });
};

const Time_Zone = (query) => {
  return useQuery(["timeZone", query], () => getTimeZone(query), {
    refetchOnWindowFocus: false,
  });
};

const get_contact_support_by_id = (id) => {
  return useQuery(["contactSupportById", id], () => getContactSupportById(id), {
    refetchOnWindowFocus: false,
    poll: 500,
    enabled: !!id,
  });
};

const edit_contact_support = () => {
  const queryClient = useQueryClient();
  return useMutation((newData) => editContactSupport(newData), {
    onMutate: async (addData) => {
      await queryClient.cancelQueries("contactSupportList");
      const prev = queryClient.getQueryData("contactSupportList");
      return {
        prev,
        addData,
      };
    },
    onSettled: () => {
      queryClient.invalidateQueries("contactSupportList");
    },
    onError: (err, _context) => {
      queryClient.setQueryData("contactSupportList", context.prev);
    },
  });
};

const del_contact_support = () => {
  const queryClient = useQueryClient();
  return useMutation((newData) => deleteContactSupport(newData), {
    onMutate: async (addData) => {
      await queryClient.cancelQueries("contactSupportList");
      const prev = queryClient.getQueryData("contactSupportList");
      return {
        prev,
        addData,
      };
    },
    onSettled: () => {
      queryClient.invalidateQueries("contactSupportList");
    },
    onError: (err, _context) => {
      queryClient.setQueryData("contactSupportList", context.prev);
    },
  });
};

const get_checkin_status = (query) => {
  return useQuery(["checkinStatus"], () => getCheckinStatus());
};

const update_checkin_status = () => {
  const queryClient = useQueryClient();
  return useMutation((data) => toggleCheckin(data), {
    onMutate: async (newData) => {
      await queryClient.cancelQueries("checkinStatus");
      const prev = queryClient.getQueriesData("checkinStatus");
      return {
        prev,
        newData,
      };
    },
    onSettled: () => {
      queryClient.invalidateQueries("checkinStatus");
    },
    onError: (err, _, context) => {
      queryClient.setQueryData("checkinStatus", context.prev);
    },
  });
};

const send_contact_support_request = () => {
  const queryClient = useQueryClient();
  return useMutation((newData) => sendSupportRequest(newData), {
    onMutate: async (addData) => {
      await queryClient.cancelQueries("contactSupportList");
      const prev = queryClient.getQueryData("contactSupportList");
      return {
        prev,
        addData,
      };
    },
    onSettled: () => {
      queryClient.invalidateQueries("contactSupportList");
      queryClient.invalidateQueries("contactSupportById");
    },
    onError: (err, _context) => {
      queryClient.setQueryData("contactSupportList", context.prev);
    },
  });
};

const get_my_attendance_details = (query) => {
  return useQuery(["myAttendanceDetails",query], () => getMyAttendanceDetails(query));
};

const get_dashboard_data = () => { 
  return useQuery(["dashboardData"], () => getDashboardData());
};

const get_notice_board_details = () => {
  return useQuery(["noticeBoardDetails"], () => getNoticeBoardDetails());
};

const get_users_list = (query) => {
  return useQuery(["usersList" , query], () => getUsersList(query));
};

const delete_user = () => {
  const queryClient = useQueryClient();
  return useMutation((newData) => deleteUser(newData), {
    onMutate: async (addData) => {
      await queryClient.cancelQueries("usersList");
      const prev = queryClient.getQueryData("usersList");
      return {
        prev,
        addData,
      };
    },
    onSettled: () => {
      queryClient.invalidateQueries("usersList");
    },
    onError: (err, _context) => {
      queryClient.setQueryData("usersList", context.prev);
    },
  });
};

const useUtilQuery = {
  get_student_search_list,
  Contact_support,
  Countries_list,
  States_list,
  Currencies_list,
  Time_Zone,
  Distrcit_list,
  cities_list,
  get_contact_support_by_id,
  del_contact_support,
  edit_contact_support,
  get_checkin_status,
  update_checkin_status,
  get_my_attendance_details,
  send_contact_support_request,
  get_dashboard_data,
  get_notice_board_details,
  get_users_list,
  delete_user
};

export default useUtilQuery;
