import { memo, Fragment } from "react";
import image from "../../../assets/images/Logo.png";
import image2 from "../../../assets/images/Logo1.png";
const Logo = memo((props) => {
  return (
    <Fragment>
      <div className="logo-main">
        <div className="logo-normal ms-3">
          <img width="155px" src={image} alt="" />
        </div>
        <div className="logo-mini">
          <img width="30px" src={image2} alt="" />
        </div>
      </div>
    </Fragment>
  );
});

Logo.displayName = "Logo";
export default Logo;
